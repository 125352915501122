<template>
<section class="combo-frame" v-if="combopackages">
    <div class="container">
        <div class="headline">
            <h2 class="headline__title">Heard About <span>Combo</span></h2>
            <span class="headline__meta">combo package tour with added benefit</span>
        </div>
        <div class="row">
            <div class="col xs-12 sm-6 md-3" v-for="(combopackage,index) in combopackages" :key="index">
                <article class="bundle">
                    <div class="bundle__img">

                        <router-link :to="{name:'Packagedetail', params:{slug:combopackage.slug,title:combopackage.seo_title,keyword:combopackage.seo_keyword,description:combopackage.seo_description}}"><img :src="urlPath+'uploads/media/package/thumbnail/326245_'+combopackage.featuredimage" alt="image description"></router-link>
                        <span class="bundle__duration">
                            <strong>{{combopackage.tripdays}}</strong> Days
                        </span>
                    </div>
                    <div class="bundle__desc">
                        <div class="txtWrapper">
                            <h3 class="bundle__desc-title" data-mh="combotitle">
                                <router-link :to="{name:'Packagedetail', params:{slug:combopackage.slug,title:combopackage.title}}" >{{combopackage.title}}</router-link>
                            </h3>
                            <p class="excerpt" data-mh="combodesc">{{truncateText(combopackage.excerpt)}}</p>
                        </div>
                        <span class="bundle__desc-rating rating"><span class="text" v-bind:style="'width:' +percentileValue(combopackage.packagerating)+'%'">3.5</span></span>
                        <span class="bundle__desc-price" v-if="combopackage.discountedprice>0">US ${{combopackage.discountedprice}}</span>
                        <span class="bundle__desc-price" v-else>US ${{combopackage.regularprice}}</span>
                    </div>
                </article>
            </div>

        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';
import jQuery from 'jquery';
export default {
    name: 'Combopackage',
    data() {
        return {
            combopackages: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    async mounted() {
        this.comboPackage();
    },
    methods: {
        comboPackage() {
            axios.get(this.apiPath+'combopackage')
                .then((response) => {
                    // console.log(response.data);
                    this.combopackages = response.data;
                })
        },
        truncateText(value, length = 80) {
            return value.length <= length ?
                // value : value.substring(0, length) + "...";
                value : value.split(' ').slice(0, 12).join(" ") + "..."
        },
        roundFigure(value){
            return Math.round(value,-1);
            
        },
        percentileValue(value){
            return value*20;
        },
    },
    async updated(){
        jQuery('.combo-frame .txtWrapper').matchHeight();
    }
    

}
</script>
